.Header{
    display: flex;
    justify-content: space-between;
}
.Header>img{
    width: 10rem;
    height: 3.5rem;
}
.header-menu{
    padding-top:5px;
    display: flex;
    gap:2rem;
    list-style: none;
    color:white;
    cursor: pointer;
}
.header-menu>li{
    gap:3rem;
}
.header-menu>li:hover{
    color:var(--orange);
}
@media screen and (max-width:768px) {
    .Header>:nth-child(2){
        position: fixed;
        right: 2rem;
        z-index: 99;
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding:2rem 3rem;
        border-radius: 22px;
    }
}
   