.Plans-container{
    display: flex;
    flex-direction: column;
    margin-top:-4px;
    padding:0 2rem;
    position: relative;
    height:110vh;
}
.p-blur1{
    width: 10rem;
    height: 10rem;
    left:2rem;
    top:10rem;
}
.p-blur2{
    width: 20rem;
    height: 20rem;
    right:1rem;
    top:10rem;
}
.p-header{
display: flex;
gap:3rem;
font-size: 3.5rem;
font-weight: bold;
margin: 1rem;
font-style: italic;
color: white;
}
.plans{
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 2rem;
    color: white;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--gray);
    padding: 1.3rem;
    width: 15rem;
}
.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>span{
    padding: 1rem 0rem;
}
.plan>span:nth-child(1){
    height: 2rem;
    width: 2rem;
}
.plan>:nth-child(2){
    font-weight: bold;
}
.plan>:nth-child(3){
    font-weight: bold;
    font-size: 2rem;
}
.features{
 padding: 4px 0px;
}
.feature{
    padding: 12px 0;
    display: flex;
}
.feature>img{
    padding-right: 9px;
    width:1rem;
    height:1rem;
}
.benefits{
    display: flex;
    padding: 2rem 1rem;
}
.benefits>img{
    width:1rem;
    height: 1rem;
    padding-left: 2rem;
}
.plans>:nth-child(2)>button{
    color:orange;
}
.plan>:nth-child(1)>svg{
    fill: rgb(255, 255, 255);
}
@media screen and (max-width:768px) {
    .p-header{
        flex-direction: column;
        align-items: center;
        font-size: 2rem;
        gap: 2rem;
    }
    .plans{
        flex-direction: column;
        gap: 3rem;
    }
}