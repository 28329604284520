.Join{
    display: flex;
    position: relative;
    padding: 2rem 4rem;
    border-bottom: 3px solid gray;
    
}
.l-join{
    font-size: 4rem;
    color: white;
    font-weight: bold;
}
.l-join>div>hr{
   position: absolute;
   border: 2px solid orange;
   width: 11.7rem;
   top:-1rem;
   left:4rem;
}
.r-join{
    display: flex;
    position: relative;
    padding: 4rem;
}
.email-container{
   background-color: gray;
   padding: 10px; 
   gap: 2rem;
   display: flex;
   margin-left: 5rem;
   border-radius: 1rem;
}
.email-container>input{
    background: none;
    border: none;
    color: white;

}
::placeholder{
    color: var(--lightgray);
    font-size: 1rem;
}
.btn-j
{   
    color: white;
    background-color:orange;
    height: 2rem;
    width: 4rem;
    border: none;
    
}
.btn-j:hover{
    border: 2px solid orange;
    background-color: white;
    color: orange;
}
@media screen and (max-width:768px){
    .Join{
        flex-direction: column;
    }
    .email-container{
        margin-left: -4rem;
    }
}