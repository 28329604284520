.Hero{
    display: flex;
    justify-content: space-between;
}
.h-blur{
    width: 20rem;
    height: 20rem;
    left:5rem;
    top:10rem;
}
.h-left{
    flex:3;
    padding:1.3rem;
    padding-top:1rem;
    display: flex;
    gap:5rem;
    flex-direction: column;
    color: white;
}
.h-right{
    flex:1;  
    position: relative;
    background-color: var(--orange);
}
.the-best-ad{
    margin-top: 5rem;
    border-radius: 30px;
    background: black;
    color: white;
    width: fit-content;
    padding:16px 17px;
    text-transform: uppercase;
    display: flex;
    flex-direction: flex-start;
    position: relative;
}
.the-best-ad>span{
    z-index:2;
}
.the-best-ad>div{
    width: 5.4rem;
    background-color: var(--orange);
    position: absolute;
    left:4px;
    border-radius: 3rem;
    height: 80%;
    z-index: 1;
    top:5.5px;
}
.hero-text{
    margin-top: -2.5rem;
    display: flex;
    flex-direction: column;
    font-size:5rem;
    font-weight: bold;
    color:white;
    text-transform: uppercase;
    text-overflow: inherit;
}
.hero-text>div:nth-of-type(3)
{
font-size: 1rem;
font-weight: 200;
text-transform: none;
letter-spacing: 1px;
width: 80%;
}

/* Experience */

.h-experience{
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    gap:3rem;

}
.h-experience>div{
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
}
.h-experience>div>span:nth-of-type(1){
    font-size: 3rem;
}
.h-experience>div>span:nth-of-type(2){
    font-size: 10px;
    color: var(--gray);
}

/* h-button */
.h-button{
    margin-top: 2rem;
    display: flex;
    gap:1rem;
    font-weight: normal;
}
.h-button>:nth-of-type(1){
    color: white;
    background-color: var(--orange);
    font-weight: 200;
    padding: 10px 14px;
}
.h-button>:nth-of-type(2){
    color: white;
    background-color: transparent;
    font-weight: 200;
    border: 1px solid var(--orange);
    padding: 10px 14px;
}

/* Right */
.h-right>button{
    position: absolute;
    right:4rem;
    top:2rem;
    color: black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 10px;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    position: absolute;
    right: 4rem;
    top:10rem;
}
.heart-rate>img{
    width:2rem;
    height: 2rem;
}
.heart-rate>:nth-child(2){
color:var(--gray);
}
.heart-rate>:nth-child(3){
    color:white;
    font-size: 1.5rem;
}
.hero-image{
    position: absolute;
    width: 23rem;
    height: 28rem;
    top:16rem;
    right:8rem;
    
}
.hero-image-back{
    position: absolute;
    width: 10rem;
    height: 20rem;
    top:10rem;
    right:23rem;
    z-index: -1;
}
.calories{
    display: flex;
    background-color: var(--gray);
    border-radius: 10px;
    position: absolute;
    top:38rem;
    right:25rem;
    padding:1rem;
    width: 13rem;
    justify-content: space-between;
}
.calories>img{
    height: 4rem;
    width: 3rem;
    padding-right: 10px;
}
.calories>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.calories>div>:nth-child(1){
    color:var(--darkGrey);
    
}
.calories>div>:nth-child(2){
    color:white;
    font-size: 1.5rem;
}
@media screen and (max-width:768px){
    .Hero{
        flex-direction: column;
    }
    .the-best-ad{
        margin-top: 0px;
    }
   
    .hero-text{
        justify-content: center;
        text-align: center;
    }
    .hero-text>div:nth-child(1){
     
        font-size: 40px;
    
    }
    .hero-text>div:nth-child(2){
       
        font-size: 35px;
    }
    .hero-text>div:nth-child(3){
        font-size: 15px;
        text-align: center;
        margin-left: 2rem;
    }
    .h-experience>div>span:nth-child(1){
       font-size: 20px;
    }
    .h-button{
        justify-content: center;
    }
    .hero-image{
        top: 2rem;
        right: -2rem;
    }
    .hero-image-back{
        top: 4rem;
        z-index: -1;
        left: 1rem;
        width: 15rem;
    height: 22rem;
    }
    .heart-rate{
        top:1rem;
        left: 16rem;
        z-index: 1;
    }
    .calories{
        left:1rem;
        top:30rem;
    }
    .button{
        display: none;
    }
}
