.Programs{
    display: flex;
    flex-direction: column;
    position: relative;
    gap:2rem;
    padding:0 2rem;      
}
.p-blur1{
    height: 20rem;
    width: 20rem;
    left:10rem
}
.P-header{
    display: flex;
    font-size: 3rem;
    justify-content: center;
    gap:5rem;
    text-transform: uppercase;
    font-size: 4rem;
    color: white;
    font-style: italic;
}
.Program-catagories{
    display: flex;
    gap:2rem;
    
}
.programs{
    display:flex ;
    flex-direction: column; 
    padding:1.3rem;
    font-size: 1.5rem;
    color: white;
    background: var(--gray);
    gap: 1.5rem;
}
.programs:hover{
    background: var(--planCard);
}
.programs>span{
    padding:1rem 0;
}

.programs>div{
    display: flex;
    gap:3rem;
}
.programs>:nth-child(1){
width: 2.5rem;
height:2.5rem;
fill: white;
}
.programs>:nth-child(2){
    font-weight: bold;

}
.programs>:nth-child(3){
    font-size: 1rem;
}
@media screen and (max-width:768px){
    .Programs{
        margin-top: 35rem;
    }
    .P-header{
        font-size: 2.5rem;
        gap:1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .P-header>span{
        font-size: 2rem;
    }
    .Program-catagories{
        display: flex;
        flex-direction: column;
    }
}