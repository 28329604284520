.testinomial{
    display: flex;
    gap:2rem;
    padding: 2rem;
    position: relative;
}
.t-left{
 display: flex;
 flex: 1 1;
 flex-direction: column; 
 gap:2rem;  
}
.t-left>span:nth-child(1){
    color: orange;
    font-weight: bold;
    
}
.t-left>span:nth-child(2){
    font-size: 4rem; 
    font-weight: bold;  
}
.t-left>span:nth-child(3){
    color:white;
    font-size: 5rem;
    font-weight: bold;
}
.t-left>span:nth-child(4){
    color:rgb(255, 255, 255);
    font-size: 1rem;
    letter-spacing: 2px;
    text-transform: none;
    text-align: justify;
    line-height: 40px;
}
.t-left>span:nth-child(5){
    color:white;
    font-size: 1rem;
}
.t-left>span>span:nth-child(1){
    color:orange;
    font-size: 1rem;
    
}
.l-name{
    gap:1rem;
}
.l-name>span:nth-child(1){
    color: orange;
    text-transform: uppercase;
}
.l-name>span:nth-child(2){
    color: white;
    text-transform: uppercase;
}
.t-right{
    flex:1;
    position: relative;
    gap: 2rem;
}
.t-right>div:nth-child(1){
    border:2px solid orange;
    height: 12rem;
    width: 12rem;
    z-index: 2;
    position: absolute;
    top:6rem;
    right: 12rem;
    z-index: -1;
}
.t-right>div:nth-child(2){
     background-color: orange;
     height: 12rem;
     width:12rem;
     z-index: -2;
     position: absolute;
     top:9rem;
     right: 10rem;
}
.t-right>img{
    position: absolute;
    height: 12rem;
    width: 12rem;
    top:7rem;
    right: 11rem;
}
.arrows{
    padding: 2rem;
    position: absolute;
    top:18rem;
    right: 23rem;
    cursor: pointer;
}
.arrows>img{
    height: 2rem;
    width: 2rem;
    padding-right: 2rem;
}
@media screen and (max-width:768px){
    .testinomial{
        margin-top: 45rem;
        flex-direction: column;
    }
    .t-right{
        top: -5rem;
        left: 10rem;
        margin-bottom: 16rem;
    
    }
    .arrows {
       display: flex;
        right: 20rem;
    }
}