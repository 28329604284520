.Reason{
padding:0 2rem;
display: flex;
grid-template: 2rem;
gap:2rem;

}
.r-left{
    flex:1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:1rem;
    grid-row: 1fr;
    
}
.r-left>:nth-child(1){
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}
.r-left>:nth-child(2){
    width: auto;
    grid-column: 2/4;
    height: 16rem;
}

.r-left>:nth-child(3){
    width: 14rem;
    height: auto;
    grid-column: 2/3;
}
.r-left>:nth-child(4){
    width: 10rem;
    grid-column: 3/4;
}
.r-right{
    flex: 1 1;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    gap:1rem; 
    
}
.r-right>span{
   font-weight: bold;
   color:var(--orange)

}
.r-right>div{
    color: white;
    font-size: 3rem;
    font-weight: bold;
}
.r-detail{
    display: flex;
    flex-direction: column;
    gap:1rem;
}
.r-detail>div{
    font-size: 1rem;
    font-weight: lighter;
    display: flex;
}
.r-detail>div>img{
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
}
.r-detail>div>span{
    font-weight: bold;
}
.partners{
    display: flex;
    gap:1rem;
}
@media screen and (max-width: 768px){
    .Reason{
        flex-direction: column;
    }
    .r-left{
    grid-auto-rows: auto;
    overflow: hidden;
    }
    .r-left>:nth-child(1){

    }
    .r-left>:nth-child(3) {
        width: 11rem;
        height: 11rem;
    }
    .r-left>:nth-child(2) {
        width: 9rem;
        height: 16rem;
    }
}

