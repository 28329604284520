.Footer{
    display: flex;
    gap:2.5rem;
    flex-direction: column;
    padding: 1rem 2rem;
    align-items: center;
}
.footer-img{
    display: flex;
    gap:5rem;
}
.footer-img>img{
    height: 2rem;
    width: 2rem;
    cursor: pointer;
}
.f-blur1{
    height: 10rem;
    width: 10rem;
    left:8rem
}
.f-blur2{
    height: 10rem;
    width: 10rem;
    background: red;
    right: 5rem;
}
@media screen and (max-width:768px){
    .Footer{
        margin-top: -5rem;
    }
    .footer-img{
        gap:3rem
    }
}